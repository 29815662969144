import { AxiosInstance } from "axios";
import { useState, useEffect } from "react";
import { Predicates } from "../libraries/predicates/predicates";
import { ICountry } from "../types/data.interface";
import { PageableDTO } from "../types/pageable";
import useAxios from "../utils/useAxios";

type SearchParams = {};

type CountryResponse = PageableDTO<ICountry[]> | ICountry[];

export const fetchCountry = async ({
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const response = await axios.get<CountryResponse>(`/country/`);

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useCountry({}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<ICountry[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchCountry({
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, []);

  return { data, isLoading };
}
