import { memo } from "react";
import { ExpandableSection } from "routes/skus/components/expandable-section";
import { ITcc } from "types/data.interface";
import { TccRegionsGroup } from "../tcc-region-card";
import { transformTccCompositionIntoRegionCompositions } from "helpers/tcc-view.helper";

const TccViewComposition = memo(({ tccData }: { tccData: ITcc }) => {
  return (
    <ExpandableSection
      title={"TCC Composition"}
      sectionId={"3"}
      expandedByDefault
      className="mb-2"
    >
      <TccRegionsGroup
        regionsCompositions={transformTccCompositionIntoRegionCompositions(
          tccData.tcc_compositions ?? [],
        )}
        readonly
      />
    </ExpandableSection>
  );
});

export default TccViewComposition;
