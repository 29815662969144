import Select from "react-select";
import {
  IGlobalProduct,
  ISkuInformation,
  ITcc,
} from "../../../../types/data.interface";
import { HierarchyInfoSection } from "../../components/hierarchy-info";
import { filterNonDigits } from "../../../../helpers";
import { useEffect } from "react";
import { useSupplierCountryContext } from "../../../../context/SupplierCountryContext";
import useTpsType from "../../../../hooks/useTpsType";
import OptionFormatter from "../../../../components/OptionFormatter";
import {
  formatTpsTypeIntoOption,
  getTpsTypes,
} from "../../../../helpers/tps-type.helper";
import {
  formatSupplierCountryIntoOption,
  getSupplierCountries,
} from "../../../../helpers/supplier-country.helper";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

export type InformationProps = {
  selectedGP: IGlobalProduct | null;
  selectedTcc: ITcc | null;
  skuData: ISkuInformation;
  setSkuData: (info: ISkuInformation) => void;
  setUnsavedChangesToTrue: () => void;
};

const Information = ({
  selectedGP,
  selectedTcc,
  skuData,
  setSkuData,
  setUnsavedChangesToTrue,
}: InformationProps) => {
  const { data: vendors, isLoading: isVendorsLoading } =
    useSupplierCountryContext();
  const { data: tpsTypes, isLoading: isTpsTypesLoading } = useTpsType({});

  const formatGiftBoxOption = (data: string) => {
    return { label: data === "Y" ? "Yes" : "No", value: data ? data : "N" };
  };
  const formatProductUseOption = (data: string) => {
    return data
      ? { label: data === "line" ? "Line" : "Promo", value: data }
      : null;
  };
  const formatTpsProductOption = (data: string) => {
    return { label: data === "Y" ? "Yes" : "No", value: data ? data : "N" };
  };

  const handleSkuDescriptionChange = (e: any) => {
    setSkuData({ ...skuData, name: e.target.value });
    setUnsavedChangesToTrue();
  };

  const handleSkuLengthChange = (e: any) => {
    setSkuData({ ...skuData, length: filterNonDigits(e.target.value) });
    setUnsavedChangesToTrue();
  };

  const handleSkuWidthChange = (e: any) => {
    setSkuData({ ...skuData, width: filterNonDigits(e.target.value) });
    setUnsavedChangesToTrue();
  };

  const handleSkuHeightChange = (e: any) => {
    setSkuData({ ...skuData, height: filterNonDigits(e.target.value) });
    setUnsavedChangesToTrue();
  };

  const handleProjectId = (e: any) => {
    setSkuData({ ...skuData, project_id: e.target.value });
    setUnsavedChangesToTrue();
  };

  const handleReleaseDateChange = (e: any) => {
    setSkuData({ ...skuData, reldt: e.target.value });
    setUnsavedChangesToTrue();
  };

  const handleProductUseChange = (e: any) => {
    if (e) {
      setSkuData({ ...skuData, product_use: e.value });
    } else {
      setSkuData({ ...skuData, product_use: "" });
    }
    setUnsavedChangesToTrue();
  };

  const handleGiftboxChange = (e: any) => {
    if (e) {
      setSkuData({ ...skuData, giftbox: e.value });
    } else {
      setSkuData({ ...skuData, giftbox: "N" });
    }
    setUnsavedChangesToTrue();
  };

  const handleTpsProductChange = (e: any) => {
    if (e) {
      setSkuData({
        ...skuData,
        tps_product: e.value,
        tps_loc_id: e.value === "Y" ? skuData.tps_loc_id : null,
        tps_type_id: e.value === "Y" ? skuData.tps_type_id : null,
      });
    } else {
      setSkuData({
        ...skuData,
        tps_product: "N",
        tps_loc_id: null,
        tps_type_id: null,
      });
    }
    setUnsavedChangesToTrue();
  };

  const handleTpsVendorChange = (e: any) => {
    if (e) {
      setSkuData({ ...skuData, tps_loc_id: e.value });
    } else {
      setSkuData({ ...skuData, tps_loc_id: null });
    }
    setUnsavedChangesToTrue();
  };

  const handleTpsTypeChange = (e: any) => {
    if (e) {
      setSkuData({ ...skuData, tps_type_id: e.value });
    } else {
      setSkuData({ ...skuData, tps_type_id: null });
    }
    setUnsavedChangesToTrue();
  };

  const handleCommentChange = (e: any) => {
    setSkuData({ ...skuData, comments: e.target.value });
    setUnsavedChangesToTrue();
  };

  useEffect(() => {
    setSkuData({
      ...skuData,
      name: skuData.name
        ? skuData.name
        : selectedTcc?.name
          ? selectedTcc.name
          : "",
    });
  }, []);

  return (
    <>
      <HierarchyInfoSection tcc={selectedTcc} gp={selectedGP} />

      <div className="card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">SKU Information</h6>
      </div>

      <div className="row align-items-end mt-2">
        <div className="col-lg-7 ">
          <label className="form-label">
            SKU Description <span className="red-text fw-bold">*</span>
          </label>
          <div className="d-flex align-items-center">
            <input
              onChange={handleSkuDescriptionChange}
              value={skuData.name}
              type="text"
              className="form-control d-inline"
            />
          </div>
        </div>
        <div className="col-1">
          <Tooltip
            id="project-id"
            className="tooltip-text-box"
            place="right"
            noArrow={true}
          />
          <label className="form-label">Project ID</label>
          <span
            className="tooltip-span"
            data-tooltip-id="project-id"
            data-tooltip-html={`Local reference number for the SKU request (ex. RX number in Workfront, TBD or NP number for the Regions)`}
          >
            <FontAwesomeIcon icon={faCircleInfo} className="tooltip-icon" />
          </span>
          <div className="d-flex align-items-center">
            <input
              onChange={handleProjectId}
              value={skuData.project_id}
              maxLength={8}
              type="text"
              className="form-control d-inline"
            />
          </div>
        </div>

        <div className="col-4">
          <div className="pb-1">
            <div className="label-3 bottom-border ">
              <label className="form-label ">
                <strong> Product Dimension</strong>
              </label>
            </div>
          </div>
          <div className="d-flex">
            <div className="col-4 label-3 pl-0">
              <label className="form-label">L (mm)</label>
              <div className="d-flex align-items-center">
                <input
                  onChange={handleSkuLengthChange}
                  value={skuData.length}
                  maxLength={6}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
            </div>
            <div className="col-4 label-3">
              <label className="form-label">W (mm)</label>
              <div className="d-flex align-items-center">
                <input
                  onChange={handleSkuWidthChange}
                  value={skuData.width}
                  maxLength={6}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
            </div>
            <div className="col-4 label-3 pr-0">
              <label className="form-label">H (mm)</label>
              <div className="d-flex align-items-center">
                <input
                  onChange={handleSkuHeightChange}
                  value={skuData.height}
                  maxLength={6}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-end mt-2">
        <div className="col-lg-4 ">
          <label className="form-label">
            Release date <span className="red-text fw-bold">*</span>
          </label>
          <div className="d-flex align-items-center">
            <input
              onChange={handleReleaseDateChange}
              value={skuData.reldt}
              type="date"
              min="1993-01-01"
              className="form-control d-inline"
            />
          </div>
        </div>

        <div className="col-lg-4 ">
          <label className="form-label">
            Product Use <span className="red-text fw-bold">*</span>
          </label>
          <Select
            options={[
              { label: "Line", value: "line" },
              { label: "Promo", value: "promo" },
            ]}
            onChange={handleProductUseChange}
            value={formatProductUseOption(skuData.product_use)}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            components={{
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
            }}
          />
        </div>

        <div className="col-lg-4 ">
          <label className="form-label">
            Gift Box <span className="red-text fw-bold">*</span>
          </label>
          <Select
            options={[
              { label: "No", value: "N" },
              { label: "Yes", value: "Y" },
            ]}
            onChange={handleGiftboxChange}
            value={formatGiftBoxOption(skuData.giftbox)}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            components={{
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
            }}
          />
        </div>
      </div>

      <div className="row align-items-end mt-2">
        <div className="col-lg-4 ">
          <label className="form-label">
            TPS Product <span className="red-text fw-bold">*</span>
          </label>
          <Select
            options={[
              { label: "No", value: "N" },
              { label: "Yes", value: "Y" },
            ]}
            onChange={handleTpsProductChange}
            value={formatTpsProductOption(skuData.tps_product)}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            components={{
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
            }}
          />
        </div>

        <div className="col-4">
          <label className="form-label">
            Vendor
            {skuData.tps_product === "Y" && (
              <span className="red-text fw-bold"> *</span>
            )}
          </label>
          <Select
            name="tpsVendor"
            value={
              skuData.tps_loc_id && skuData.tps_loc_id.id !== "0"
                ? formatSupplierCountryIntoOption(skuData.tps_loc_id)
                : null
            }
            onChange={handleTpsVendorChange}
            options={getSupplierCountries(vendors)}
            formatOptionLabel={OptionFormatter}
            isLoading={isVendorsLoading}
            isDisabled={skuData.tps_product === "N"}
            isClearable
            isSearchable
            placeholder="(min 3 digits)"
            classNamePrefix="react-select"
          />
        </div>

        <div className="col-4">
          <label className="form-label">
            TPS Type
            {skuData.tps_product === "Y" && (
              <span className="red-text fw-bold"> *</span>
            )}
          </label>
          <Select
            name="tpsType"
            value={
              skuData.tps_type_id && skuData.tps_type_id.res_pis_type_id !== "0"
                ? formatTpsTypeIntoOption(skuData.tps_type_id)
                : null
            }
            onChange={handleTpsTypeChange}
            options={getTpsTypes(tpsTypes)}
            formatOptionLabel={OptionFormatter}
            isLoading={isTpsTypesLoading}
            isDisabled={skuData.tps_product === "N"}
            isClearable
            isSearchable
            placeholder=""
            classNamePrefix="react-select"
          />
        </div>
      </div>

      <div className="col mt-2">
        <label className="form-label">Comment</label>
        <textarea
          value={skuData.comments}
          rows={3}
          className="form-control mb-2"
          onChange={handleCommentChange}
        />
      </div>
    </>
  );
};

export default Information;
