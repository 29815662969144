import Select from "react-select";

const DropListShowEntriesLimit = ({ showEntriesLimit, onChange }: any) => {
  const options = [
    { label: "50", value: "50" },
    { label: "100", value: "100" },
    { label: "200", value: "200" },
    { label: "500", value: "500" },
  ];

  return (
    <>
      <div className="row mb-2">
        <div className="col-lg d-flex  px-0 black-text">
          <label className="mr-1">Show</label>
          <Select
            onChange={onChange}
            value={{ label: showEntriesLimit, value: showEntriesLimit }}
            options={options}
            placeholder=""
            isClearable={false}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            className="fw-bold"
            classNamePrefix="react-select"
          />
          <label className="ml-1">entries</label>
        </div>
      </div>
    </>
  );
};
export default DropListShowEntriesLimit;
