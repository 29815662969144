import { useContext, useRef } from "react";
import ButtonIconModal from "../../../../components/ButtonIconModal";
import AuthContext from "../../../../context/AuthContext";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import { TccRegionsGroup } from "../../components/tcc-region-card";
import { AddRegionModal } from "../../components/tcc-region-modal";
import { ListingModalRef } from "components/ButtonModal";

export type ValuesProps = {};

const Values = ({}: ValuesProps) => {
  const { state } = useCreateTccStore();
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const modalRef = useRef<ListingModalRef>(null);

  return (
    <>
      {hasPermission("api.global_approver_tcc_request") && (
        <div className="d-flex justify-content-between my-3">
          <p className="font-italic">
            {`Please review the values for the Global Mkt region. Please click on "Add a Region" to fill values for a specific region.`}
          </p>

          <ButtonIconModal
            buttonIconClass="d-inline-block"
            description="Add a Region"
            title={`Add a new region`}
            buttonStyleClass="btn-primary"
            ref={modalRef}
          >
            <AddRegionModal
              regionsCompositions={state.tccRegionCompositions}
              parentModalRef={modalRef}
            />
          </ButtonIconModal>
        </div>
      )}
      {!hasPermission("api.global_approver_tcc_request") && (
        <div className="d-flex justify-content-between my-3">
          <p className="font-italic">
            {`Please define the values for the Global Mkt region.`}
          </p>
        </div>
      )}

      <TccRegionsGroup regionsCompositions={state.tccRegionCompositions} />
    </>
  );
};

export default Values;
