import OptionFormatter from "../../../../../../components/OptionFormatter";
import AsyncSelect from "react-select/async";

const DropListFieldAsyncSelect = ({
  label,
  name,
  placeholder,
  value,
  isLoading,
  onChange,
  loadOptions,
  defaultOptions,
  cacheOptions,
  isOptionDisabled,
  components,
  isClearable,
  isSearchable,
}: any) => {
  return (
    <>
      <div className="col-lg-2 form-group">
        <label className="form-label">{label}</label>
        <AsyncSelect
          name={name}
          value={value}
          onChange={onChange}
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          formatOptionLabel={OptionFormatter}
          isLoading={isLoading}
          isClearable={isClearable || true}
          isSearchable={isSearchable || false}
          placeholder={placeholder}
          classNamePrefix="react-select"
          cacheOptions={cacheOptions}
          isOptionDisabled={isOptionDisabled}
          components={components}
        />
      </div>
    </>
  );
};

export default DropListFieldAsyncSelect;
