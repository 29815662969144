import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import ButtonIconModal from "components/ButtonIconModal";
import { formatDatacalogIntoOption } from "helpers/datacatalog.helper";
import { formatGlobalProductIntoOption } from "helpers/global-product.helper";
import { formatTccIntoOption } from "helpers/tcc.helper";
import { memo } from "react";
import { GlobalProductTemplate } from "routes/skus/components/attributes-modal";
import { ExpandableSection } from "routes/skus/components/expandable-section";
import { ITcc } from "types/data.interface";

const TccViewHierarchy = memo(({ tccData }: { tccData: ITcc }) => {
  return (
    <ExpandableSection
      title={"TCC Hierarchy"}
      sectionId={"1"}
      expandedByDefault
      className="mb-2"
    >
      <div className="row">
        <div className="col-4">
          <label className="form-label">Category</label>
          <textarea
            value={
              tccData.global_product.sub_category.parent
                ? formatDatacalogIntoOption(
                    tccData.global_product.sub_category.parent,
                  ).label
                : ""
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
        <div className="col-4">
          <label className="form-label">Sub-Category</label>
          <textarea
            value={
              formatDatacalogIntoOption(tccData.global_product.sub_category)
                .label
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
        <div className="col-4">
          <label className="form-label">Product Line</label>
          <textarea
            value={
              formatDatacalogIntoOption(tccData.global_product.product_line)
                .label
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <label className="form-label">Global Product Code / Name</label>
          {
            <ButtonIconModal
              buttonIconClass="mx-3 d-inline-block"
              description="show attributes"
              title={`Global Product ${
                formatGlobalProductIntoOption(tccData.global_product).label
              }`}
              icon={faCirclePlus}
              iconClass="me-2"
            >
              <GlobalProductTemplate model={tccData.global_product} />
            </ButtonIconModal>
          }
          <textarea
            value={formatGlobalProductIntoOption(tccData.global_product).label}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-6">
          <label className="form-label">TCC Code / Name</label>
          <textarea
            value={formatTccIntoOption(tccData).label}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>
    </ExpandableSection>
  );
});

export default TccViewHierarchy;
