import { ReactNode, RefObject, useRef } from "react";
import ButtonIconModal from "../../../../components/ButtonIconModal";
import { ListingModalRef } from "components/ButtonModal";

export type CreateTccActionButtonProps = {
  show: boolean;
  description: string;
  isButtonModal: boolean;
  clickHandler: (...args: any) => any;
  buttonStyleClass: string;
  disabled?: boolean;
  className?: string;
  modalTitle?: string;
  modal?: ReactNode;
  modalRef?: RefObject<ListingModalRef>;
};

const CreateTccActionButton = ({
  show,
  description,
  clickHandler,
  buttonStyleClass,
  disabled,
  className,
  isButtonModal,
  modalTitle,
  modal,
  modalRef,
}: CreateTccActionButtonProps) => {
  return (
    <>
      {show && (
        <>
          {isButtonModal ? (
            <ButtonIconModal
              buttonIconClass="d-inline-block"
              description={description}
              title={modalTitle ?? ""}
              buttonStyleClass={buttonStyleClass}
              disabled={disabled}
              ref={modalRef}
            >
              {modal}
            </ButtonIconModal>
          ) : (
            <button
              className={`btn ${buttonStyleClass} ${className ?? ""}`}
              disabled={disabled}
              onClick={clickHandler}
            >
              {description}
            </button>
          )}
        </>
      )}
    </>
  );
};

export default CreateTccActionButton;
