import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OptionFormatter from "../../../../components/OptionFormatter";
import {
  formatComponentTypeIntoOption,
  yesOrNoOptions,
} from "../../../../helpers";
import { ExpandableSection } from "../../../skus/components/expandable-section";
import Select, { components } from "react-select";
import {
  faCircleInfo,
  faClone,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { AgGridReact } from "ag-grid-react";
import { useContext, useMemo, useRef } from "react";
import {
  ITccCompositionRestriction,
  ITccRegionComposition,
} from "../../../../types/data.interface";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import { CreateTccActions } from "../../../../states/create-tcc";
import { formatMoldIntoOption } from "../../../../helpers/mold.helper";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { formatColorIntoOption } from "../../../../helpers/color.helper";
import { formatDecorTechIntoOption } from "../../../../helpers/decor-tech.helper";
import { formatDesignIntoOption } from "../../../../helpers/design.helper";
import { generateNodeId } from "../../../skus/features/sku-create/sku-create.helper";
import ButtonIconModal from "../../../../components/ButtonIconModal";
import { toast } from "react-toastify";
import AddEditRestrictionForm from "./AddEditRestrictionForm";
import AuthContext from "../../../../context/AuthContext";
import { ConfirmRegionDeleteModal } from "../confirmation-modals";
import { ListingModalRef } from "components/ButtonModal";
import { hasRepeatedRestrictionId } from "helpers/create-tcc.helper";

const TccRegionCard = ({
  regionComposition,
  readOnly,
}: {
  regionComposition: ITccRegionComposition;
  readOnly?: boolean;
}) => {
  const { dispatch } = useCreateTccStore();
  const gridRef = useRef<AgGridReact>(null);
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const modalRef = useRef<ListingModalRef>(null);

  const columnDefs = useMemo(
    () => [
      {
        valueGetter: (params: any) =>
          formatComponentTypeIntoOption(params.data.componentType)?.label,
        headerName: "Component Type",
        maxWidth: 150,
      },
      {
        valueGetter: (params: any) =>
          Predicates.isNotNullAndNotUndefined(params.data.mold) ||
          Predicates.isNotNullAndNotUndefined(params.data.nonMold)
            ? formatMoldIntoOption(
                params.data.componentType === "Molded"
                  ? params.data.mold
                  : params.data.nonMold,
              )?.label
            : "",
        headerName: "Mold/Sequence",
        flex: 1,
      },
      {
        valueGetter: (params: any) =>
          Predicates.isNotNullAndNotUndefined(params.data.color)
            ? formatColorIntoOption(params.data.color)?.label
            : "",
        headerName: "Color",
        flex: 1,
      },
      {
        valueGetter: (params: any) =>
          Predicates.isNotNullAndNotUndefined(params.data.decorTech)
            ? formatDecorTechIntoOption(params.data.decorTech)?.label
            : "",
        headerName: "Decoration Technique",
        maxWidth: 150,
      },
      {
        valueGetter: (params: any) =>
          Predicates.isNotNullAndNotUndefined(params.data.artwork)
            ? formatDesignIntoOption(params.data.artwork)?.label
            : "",
        headerName: "Artwork",
        flex: 1,
      },
      { field: "pcs", headerName: "Pcs / Sets", maxWidth: 75 },
      {
        field: "index",
        headerName: "Mold Index",
        maxWidth: 75,
        hide: !hasPermission("api.global_approver_tcc_request"),
      },
      {
        headerName: "",
        sortable: false,
        hide: readOnly,
        maxWidth: 100,
        cellRenderer: (params: any) => {
          return (
            <div className="d-flex justify-content-center gap-2">
              <ButtonIconModal
                title={`${regionComposition.region.name} - Edit Restriction`}
                description={""}
                buttonIconClass={""}
                icon={faPencil}
                iconSize="lg"
                ref={modalRef}
              >
                <AddEditRestrictionForm
                  isEdit
                  actionHandler={editRestriction}
                  restriction={params.data}
                  parentModalRef={modalRef}
                />
              </ButtonIconModal>
              <FontAwesomeIcon
                icon={faClone}
                size={"lg"}
                color={"black"}
                cursor={"pointer"}
                onClick={() => cloneRestriction(params.data.restrictionId)}
              />
              <FontAwesomeIcon
                icon={faTrash}
                size={"lg"}
                color={"black"}
                cursor={"pointer"}
                onClick={() => removeRestriction(params.data.restrictionId)}
              />
            </div>
          );
        },
      },
    ],
    [regionComposition],
  );

  const defaultColDef = useMemo(
    () => ({
      sortingOrder: ["asc" as const, "desc" as const],
      minWidth: 100,
      sortable: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      suppressMenu: true,
      suppressMovable: true,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
        filter: "<i class='fa fa-filter'></i>",
      },
    }),
    [],
  );

  const addRestriction = (newRestriction: ITccCompositionRestriction) => {
    const updatedRegionComposition: ITccRegionComposition =
      buildUpdatedRegionComposition("add", undefined, newRestriction);
    dispatch(
      CreateTccActions.updateRegionComposition(updatedRegionComposition),
    );
  };

  const editRestriction = (updatedRestriction: ITccCompositionRestriction) => {
    const updatedRegionComposition: ITccRegionComposition =
      buildUpdatedRegionComposition(
        "edit",
        updatedRestriction.restrictionId,
        updatedRestriction,
      );
    dispatch(
      CreateTccActions.updateRegionComposition(updatedRegionComposition),
    );
    toast.success(`Restriction edited successfully`);
  };

  const cloneRestriction = (restrictionIdToClone: number) => {
    const updatedRegionComposition: ITccRegionComposition =
      buildUpdatedRegionComposition("clone", restrictionIdToClone);
    dispatch(
      CreateTccActions.updateRegionComposition(updatedRegionComposition),
    );
    toast.success(`Restriction cloned successfully`);
  };

  const removeRestriction = (restrictionIdToRemove: number) => {
    const updatedRegionComposition: ITccRegionComposition =
      buildUpdatedRegionComposition("remove", restrictionIdToRemove);
    dispatch(
      CreateTccActions.updateRegionComposition(updatedRegionComposition),
    );
    toast.success(`Restriction removed successfully`);
  };

  const buildUpdatedRegionComposition = (
    action: "add" | "edit" | "remove" | "clone",
    restrictionId?: number,
    newRestriction?: ITccCompositionRestriction,
  ): ITccRegionComposition => {
    let newRestrictionsList: ITccCompositionRestriction[] = [];
    switch (action) {
      case "add":
        if (Predicates.isNotNullAndNotUndefined(newRestriction)) {
          let newRestricionId: number = newRestriction.restrictionId;
          while (
            hasRepeatedRestrictionId(
              newRestricionId,
              regionComposition.restrictions,
            )
          ) {
            newRestricionId = generateNodeId();
          }

          newRestrictionsList = [
            ...regionComposition.restrictions,
            { ...newRestriction, restrictionId: newRestricionId },
          ];
        }
        break;
      case "edit":
        if (Predicates.isNotNullAndNotUndefined(newRestriction)) {
          newRestrictionsList = [...regionComposition.restrictions];
          newRestrictionsList.splice(
            regionComposition.restrictions.findIndex(
              (restriction) => restriction.restrictionId === restrictionId,
            ),
            1,
            newRestriction,
          );
        }
        break;
      case "clone":
        newRestrictionsList = [
          ...regionComposition.restrictions,
          copyRestriction(
            regionComposition.restrictions.find(
              (restriction) => restriction.restrictionId === restrictionId,
            ) ?? null,
          ),
        ];
        break;
      case "remove":
        newRestrictionsList = [...regionComposition.restrictions];
        newRestrictionsList.splice(
          regionComposition.restrictions.findIndex(
            (restriction) => restriction.restrictionId === restrictionId,
          ),
          1,
        );
        break;
      default:
        break;
    }
    return {
      ...regionComposition,
      restrictions: newRestrictionsList,
    };
  };

  const copyRestriction = (
    restrictionToClone: ITccCompositionRestriction | null,
  ): ITccCompositionRestriction => {
    return Predicates.isNotNullAndNotUndefined(restrictionToClone)
      ? {
          ...restrictionToClone,
          restrictionId: generateNodeId(),
        }
      : { componentType: "Molded", restrictionId: generateNodeId() };
  };

  const handleIsRestrictive = (e: any) => {
    dispatch(
      CreateTccActions.updateRegionComposition({
        ...regionComposition,
        isRestrictive: !regionComposition.isRestrictive,
      }),
    );
  };

  return (
    <>
      <ExpandableSection
        title={regionComposition.region.name}
        sectionId={regionComposition.region.id.toString()}
        expandedByDefault
      >
        <div className="d-flex justify-content-between mb-1">
          {hasPermission("api.global_approver_tcc_request") && (
            <div className="d-flex align-items-center gap-2">
              <span className="black-text">Is restrictive? </span>
              <Select
                name="is-restrictive"
                value={
                  regionComposition.isRestrictive
                    ? { label: "Yes", value: "Y" }
                    : { label: "No", value: "N" }
                }
                onChange={handleIsRestrictive}
                options={yesOrNoOptions}
                formatOptionLabel={OptionFormatter}
                isSearchable
                placeholder=""
                classNamePrefix="react-select"
                isDisabled={readOnly}
                components={{
                  DropdownIndicator: (props) =>
                    readOnly ? null : (
                      <components.DropdownIndicator {...props} />
                    ),
                }}
              />
              <Tooltip
                id="restrictive"
                className="tooltip-text-box"
                place="right"
                noArrow={true}
              />
              <span
                className="tooltip-span ml-0"
                data-tooltip-id="restrictive"
                data-tooltip-html={`By defining a region as restrictive the user will only have access to the selected values, for this specific region, later in SKU Composition step in the Create new SKU process. <br/> By defining a region as not restrictive, the user will have access to all values without any limitation.`}
              >
                <FontAwesomeIcon icon={faCircleInfo} className="tooltip-icon" />
              </span>
            </div>
          )}

          {!readOnly &&
            hasPermission("api.global_approver_tcc_request") &&
            regionComposition.region.name !== "GLOBAL MKT" && (
              <>
                <ButtonIconModal
                  buttonIconClass="d-inline-block"
                  description={"Delete"}
                  title={"Confirm Delete of Region"}
                  buttonStyleClass={"btn-danger"}
                  ref={modalRef}
                >
                  <ConfirmRegionDeleteModal
                    onConfirm={() => {
                      dispatch(
                        CreateTccActions.deleteRegionComposition(
                          regionComposition,
                        ),
                      );
                    }}
                    parentModalRef={modalRef}
                  />
                </ButtonIconModal>
              </>
            )}
        </div>

        {!readOnly && (
          <AddEditRestrictionForm
            isEdit={false}
            actionHandler={addRestriction}
          />
        )}

        <div className="ag-theme-alpine">
          <AgGridReact
            domLayout="autoHeight"
            ref={gridRef}
            rowData={regionComposition.restrictions}
            getRowId={(params: any) => params.data.restrictionId}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection
            unSortIcon
            suppressPaginationPanel
          ></AgGridReact>
        </div>
      </ExpandableSection>
    </>
  );
};

export default TccRegionCard;
