import Select from "react-select";

const DropListShowLastDays = ({ showLastDaysLimit, onChange }: any) => {
  const options = [
    { label: "30", value: "30" },
    { label: "60", value: "60" },
    { label: "90", value: "90" },
    { label: "All", value: "All" },
  ];

  return (
    <>
      <div className="col-md-3 form-group">
        <div className="col-lg d-flex px-0 black-text">
          <label className="mr-1">Show results from last</label>

          <Select
            onChange={onChange}
            value={{ label: showLastDaysLimit, value: showLastDaysLimit }}
            options={options}
            placeholder=""
            isClearable={false}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            className="fw-bold"
            classNamePrefix="react-select"
          />
          <label className="ml-1">days</label>
        </div>
      </div>
    </>
  );
};
export default DropListShowLastDays;
