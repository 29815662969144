import { ReactNode, createContext, useContext, useReducer } from "react";

import { InitialContext } from "../types/state/context";
import { CreateTccReducer } from "../states/create-tcc";
import {
  CreateTccActionTypes,
  CreateTccState,
} from "../states/create-tcc/data.type";

type Context = InitialContext<CreateTccState, CreateTccActionTypes>;

export const CreateTccContext = createContext<Context>({} as Context);

export function CreateTccProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(
    CreateTccReducer.reducer,
    CreateTccReducer.initialState,
  );

  return (
    <CreateTccContext.Provider value={{ state, dispatch }}>
      {children}
    </CreateTccContext.Provider>
  );
}

export const useCreateTccStore = () => useContext(CreateTccContext);
