import AsyncSelect from "react-select/async";
import { ExpandableSection } from "../../../skus/components/expandable-section";
import Select, { components } from "react-select";
import { useContext } from "react";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";
import {
  formatLifecycleIntoOption,
  lifecycleStatusOptions,
} from "../../../../helpers";
import OptionFormatter from "../../../../components/OptionFormatter";
import { useColorContext } from "../../../../context/ColorContext";
import useAxios from "../../../../utils/useAxios";
import { fetchColor } from "../../../../hooks/useColor";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import {
  formatColorIntoOption,
  getColors,
} from "../../../../helpers/color.helper";
import {
  formatMarketingMaterialIntoOption,
  getMarketingMaterials,
} from "../../../../helpers/marketing-material.helper";
import { useDecorationTechContext } from "../../../../context/DecorationTechContext";
import {
  formatDecorTechIntoOption,
  getDecorTechs,
} from "../../../../helpers/decor-tech.helper";
import {
  formatDesignIntoOption,
  getDesigns,
} from "../../../../helpers/design.helper";
import { IDesign, ILicensedProperty } from "../../../../types/data.interface";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { fetchDesign } from "../../../../hooks/useDesign";
import {
  formatChannelIntoOption,
  getChannels,
} from "../../../../helpers/channel.helper";
import {
  formatFlavorScentIntoOption,
  getFlavorScents,
} from "../../../../helpers/flavor-scent.helper";
import {
  formatLicensorIntoOption,
  getLicensors,
} from "../../../../helpers/licensor.helper";
import {
  formatLicensedPropertyIntoOption,
  getLicensedPropertys,
} from "../../../../helpers/licensed-property.helper";
import { fetchLicensedProperty } from "../../../../hooks/useLicensedProperty";
import { CreateTccActions } from "../../../../states/create-tcc";
import AuthContext from "../../../../context/AuthContext";
import { useChannelContext } from "context/ChannelContext";
import { useMarketingMaterialContext } from "context/MarketingMaterialContext";
import { useFlavorScentContext } from "context/FlavorScentContext";
import { useLicensorContext } from "context/LicensorContext";

export type AttributesSectionProps = {
  designs?: IDesign[];
  isDesignsLoading?: boolean;
  licensedProperties?: ILicensedProperty[];
  isLicensedPropertiesLoading?: boolean;
  readOnly?: boolean;
  className?: string;
  isEdit: boolean;
};

const TccAttributesSection = ({
  designs,
  isDesignsLoading,
  licensedProperties,
  isLicensedPropertiesLoading,
  readOnly,
  className,
  isEdit,
}: AttributesSectionProps) => {
  const axios = useAxios();
  const { state, dispatch } = useCreateTccStore();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);

  const { data: colorsFetched, isLoading: isColorsLoading } = useColorContext();
  const { data: decorTechs, isLoading: isDecorTechsLoading } =
    useDecorationTechContext();

  const { data: materialsMarketing, isLoading: isMaterialsMarketingLoading } =
    useMarketingMaterialContext();
  const { data: channels, isLoading: isChannelsLoading } = useChannelContext();
  const { data: flavorScents, isLoading: isFlavorScentsLoading } =
    useFlavorScentContext();
  const { data: licensors, isLoading: isLicensorsLoading } =
    useLicensorContext();

  const loadColors = async (input: string, callback: any) => {
    let options: any = getColors(colorsFetched);
    if (input.length > 2) {
      const response = await fetchColor({ search: input, axios });
      options = getColors(response);
    }
    callback(options);
  };
  const tccArtworkOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3)
      return getDesigns(designs ?? []);
    const response = await fetchDesign({
      decorationTechniqueId: String(state.decorationTechnique?.id ?? ""),
      search,
      axios,
    });
    callback(getDesigns(response));
  };
  const licensedPropertiesOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3)
      return getLicensedPropertys(licensedProperties ?? []);
    const response = await fetchLicensedProperty({
      licensorId: String(state.licensor?.id?.toString() ?? ""),
      search,
      axios,
    });
    callback(getLicensedPropertys(response));
  };

  const handleProductColor = (item: any) => {
    dispatch(CreateTccActions.setProductColor(item?.value ?? null));
  };
  const handleMaterialMarketing = (item: any) => {
    dispatch(CreateTccActions.setMaterialMarketing(item?.value ?? null));
  };
  const handleDecorationTech = (item: any) => {
    dispatch(CreateTccActions.setDecorationTechnique(item?.value ?? null));
  };
  const handleArtwork = (item: any) => {
    dispatch(CreateTccActions.setArtwork(item?.value ?? null));
  };
  const handleChannel = (item: any) => {
    dispatch(CreateTccActions.setChannel(item?.value ?? null));
  };
  const handlePcsSellingUnits = (item: any) => {
    dispatch(CreateTccActions.setPcsSellingUnits(item.target.value));
  };
  const handleConsumerFacingName = (item: any) => {
    dispatch(CreateTccActions.setConsumerFacingName(item.target.value));
  };
  const handleFlavorScent = (item: any) => {
    dispatch(CreateTccActions.setFlavorScent(item?.value ?? null));
  };
  const handleLicensor = (item: any) => {
    dispatch(CreateTccActions.setLicensor(item?.value ?? null));
  };
  const handleLicensedProperty = (item: any) => {
    dispatch(CreateTccActions.setLicensedProperty(item?.value ?? null));
  };
  // const handleGlobalStandardCost = (item: any) => {
  //   dispatch(CreateTccActions.setGlobalStandardCost(item.target.value));
  // };
  // const handleGlobalMsrp = (item: any) => {
  //   dispatch(CreateTccActions.setGlobalMsrp(item.target.value));
  // };
  const handleProductLifecycleStatus = (item: any) => {
    dispatch(
      CreateTccActions.setProductionLifecycleStatus(item?.value ?? null),
    );
  };
  const handleSalesLifecycleStatus = (item: any) => {
    dispatch(CreateTccActions.setSalesLifecycleStatus(item?.value ?? null));
  };
  const handleUpc = (item: any) => {
    dispatch(CreateTccActions.setUpcCode(item.target.value));
  };
  const handleGtinCode = (item: any) => {
    dispatch(CreateTccActions.setGtinCode(item.target.value));
  };
  // const handleUsc = (item: any) => {
  //   dispatch(CreateTccActions.setUsc(item.target.value));
  // };
  const handleColor1 = (item: any) => {
    dispatch(CreateTccActions.setColor1(item?.value));
  };
  const handleColor2 = (item: any) => {
    dispatch(CreateTccActions.setColor2(item?.value));
  };

  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;

  return (
    <ExpandableSection
      sectionId={"attributes"}
      title="TCC Attributes Definition"
      className={className}
      expandedByDefault={true}
    >
      <>
        <div className="row mb-1">
          <div className="col-md-3 form-group">
            <label className="form-label">
              Product Color{" "}
              {hasPermission("api.global_approver_tcc_request") && (
                <span className="red-text fw-bold">*</span>
              )}
            </label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadColors,
                  input,
                  getColors(colorsFetched) ?? [],
                  callback,
                );
              }}
              defaultOptions={getColors(colorsFetched)}
              onChange={handleProductColor}
              value={formatColorIntoOption(state.productColor)}
              placeholder={readOnly ? "" : "(min 3 characters)"}
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isColorsLoading}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={
                readOnly || !hasPermission("api.global_approver_tcc_request")
              }
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">
              Material (Marketing) <span className="red-text fw-bold">*</span>
            </label>
            <Select
              options={getMarketingMaterials(materialsMarketing)}
              onChange={handleMaterialMarketing}
              value={formatMarketingMaterialIntoOption(state.materialMarketing)}
              placeholder=""
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isMaterialsMarketingLoading}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={readOnly}
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Decoration Technique</label>
            <Select
              options={getDecorTechs(decorTechs)}
              onChange={handleDecorationTech}
              value={formatDecorTechIntoOption(state.decorationTechnique)}
              placeholder={readOnly ? "" : "(min 3 characters)"}
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isDecorTechsLoading}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={readOnly}
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Artwork</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  tccArtworkOptions,
                  input,
                  getDesigns(designs ?? []),
                  callback,
                );
              }}
              defaultOptions={getDesigns(designs ?? [])}
              onChange={handleArtwork}
              value={formatDesignIntoOption(state.artwork)}
              placeholder={readOnly ? "" : "(min 3 characters)"}
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isDesignsLoading}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={
                readOnly ||
                Predicates.isNullOrUndefined(state.decorationTechnique) ||
                state.decorationTechnique.id === "0"
              }
            />
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-md-3 form-group">
            <label className="form-label">
              Channel <span className="red-text fw-bold">*</span>
            </label>
            <Select
              options={getChannels(channels)}
              onChange={handleChannel}
              value={formatChannelIntoOption(state.channel)}
              placeholder=""
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isChannelsLoading}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={readOnly}
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">
              No of Pieces in Selling Unit (Sets){" "}
              <span className="red-text fw-bold">*</span>
            </label>
            <input
              value={state.pcsSellingUnits}
              onChange={handlePcsSellingUnits}
              type="text"
              className="form-control"
              disabled={readOnly}
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Consumer Facing Name </label>
            <textarea
              value={state.consumerFacingName}
              onChange={handleConsumerFacingName}
              rows={1}
              className="form-control"
              maxLength={255}
              disabled={
                readOnly || !hasPermission("api.global_approver_tcc_request")
              }
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Flavor/Scent</label>
            <Select
              options={getFlavorScents(flavorScents)}
              onChange={handleFlavorScent}
              value={formatFlavorScentIntoOption(state.flavorScent)}
              placeholder=""
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isFlavorScentsLoading}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={readOnly}
            />
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-md-3 form-group">
            <label className="form-label">Licensor</label>
            <Select
              options={getLicensors(licensors)}
              onChange={handleLicensor}
              value={formatLicensorIntoOption(state.licensor)}
              placeholder=""
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isLicensorsLoading}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={readOnly}
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Licensed Property</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  licensedPropertiesOptions,
                  input,
                  getLicensedPropertys(licensedProperties ?? []),
                  callback,
                );
              }}
              defaultOptions={getLicensedPropertys(licensedProperties ?? [])}
              onChange={handleLicensedProperty}
              value={formatLicensedPropertyIntoOption(state.licensedProperty)}
              placeholder={readOnly ? "" : "(min 3 characters)"}
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isLicensedPropertiesLoading}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={
                readOnly || Predicates.isNullOrUndefined(state.licensor)
              }
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Royalty Item</label>
            <input
              value={
                Predicates.isNotNullAndNotUndefined(state.licensor)
                  ? state.licensor.is_royalty
                    ? "Yes"
                    : "No"
                  : ""
              }
              className="form-control"
              type="text"
              disabled
            />
          </div>

          {/* <div className="col-md-3 form-group">
            <label className="form-label">Global Standard Cost</label>
            <input
              value={state.globalStandardCost}
              onChange={handleGlobalStandardCost}
              type="text"
              className="form-control"
              disabled={readOnly}
            />
          </div> */}
        </div>

        <div className="row mb-1">
          {/* <div className="col-md-3 form-group">
            <Tooltip
              id="global-msrp"
              className="tooltip-text-box"
              place="top-start"
              noArrow={true}
            />
            <label
              className="form-label"
              data-tooltip-id="global-msrp"
              data-tooltip-html={`<strong>Global Manufacturer´s Suggested Retail Price</strong>`}
            >
              Global MSRP
            </label>
            <input
              value={state.globalMsrp}
              onChange={handleGlobalMsrp}
              type="text"
              className="form-control"
              disabled={readOnly}
            />
          </div> */}

          <div className="col-md-3 form-group">
            <label className="form-label">Production Lifecycle Status</label>
            <Select
              onChange={handleProductLifecycleStatus}
              formatOptionLabel={OptionFormatter}
              value={formatLifecycleIntoOption(state.productionLifecycleStatus)}
              options={lifecycleStatusOptions}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={
                readOnly || !hasPermission("api.global_approver_tcc_request")
              }
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Sales Lifecycle Status</label>
            <Select
              onChange={handleSalesLifecycleStatus}
              formatOptionLabel={OptionFormatter}
              value={formatLifecycleIntoOption(state.salesLifecycleStatus)}
              options={lifecycleStatusOptions}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={
                readOnly || !hasPermission("api.global_approver_tcc_request")
              }
            />
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-md-3 form-group">
            <label className="form-label">Color 1</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadColors,
                  input,
                  getColors(colorsFetched) ?? [],
                  callback,
                );
              }}
              defaultOptions={getColors(colorsFetched)}
              onChange={handleColor1}
              value={formatColorIntoOption(state.color1)}
              placeholder={readOnly ? "" : "(min 3 characters)"}
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isColorsLoading}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={
                readOnly || !hasPermission("api.global_approver_tcc_request")
              }
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Color Family 1</label>
            <textarea
              value={state.color1?.color_family_name ?? ""}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Color 2</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadColors,
                  input,
                  getColors(colorsFetched) ?? [],
                  callback,
                );
              }}
              defaultOptions={getColors(colorsFetched)}
              onChange={handleColor2}
              value={formatColorIntoOption(state.color2)}
              placeholder={readOnly ? "" : "(min 3 characters)"}
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isColorsLoading}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={
                readOnly || !hasPermission("api.global_approver_tcc_request")
              }
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Color Family 2</label>
            <textarea
              value={state.color2?.color_family_name ?? ""}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
          </div>
        </div>
        {hasPermission("api.global_approver_tcc_request") && isEdit && (
          <div className="row">
            {/* <div className="col-md-3 form-group">
            <label className="form-label">USC</label>
            <input
              value={state.usc}
              onChange={handleUsc}
              className="form-control"
              type="text"
              maxLength={13}
              disabled={readOnly}
            />
          </div> */}

            <div className="col-md-3 form-group">
              <label className="form-label">UPC 12 Digits US</label>
              <input
                value={state.upcCode}
                onChange={handleUpc}
                className="form-control"
                type="text"
                maxLength={12}
                disabled={
                  readOnly || !hasPermission("api.global_approver_tcc_request")
                }
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">GTIN 14 Digits RoW</label>
              <input
                value={state.gtinCode}
                onChange={handleGtinCode}
                className="form-control"
                type="text"
                maxLength={14}
                disabled={
                  readOnly || !hasPermission("api.global_approver_tcc_request")
                }
              />
            </div>
          </div>
        )}
      </>
    </ExpandableSection>
  );
};

export default TccAttributesSection;
