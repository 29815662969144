const TextFieldSearchRequestor = ({ searchRequestor, onChange }: any) => {
  return (
    <>
      <div className="row form-row mb-2">
        <div className="col-lg d-flex align-items-center">
          <label className="px-0 mb-0 align-self-center mr-1 black-text">
            Requestor:
          </label>
          <input
            type="search"
            className="table-top-input col-6 form-control"
            value={searchRequestor}
            onChange={onChange}
            placeholder="(min 3 characters)"
          />
        </div>
      </div>
    </>
  );
};

export default TextFieldSearchRequestor;
