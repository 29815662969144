import { DateTimeFormat } from "libraries/date-time-format/date-time-format";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import { ITccRequestHistory } from "../../../../types/data.interface";
import { ExpandableSection } from "../../../skus/components/expandable-section";

const TccRequestHistorySection = () => {
  const { state } = useCreateTccStore();

  const getStatusExtraInformation = (history: ITccRequestHistory): string => {
    if (history.status === "REJECTED") {
      return ` - ${history.rejectReason}`;
    } else if (history.status === "APPROVED") {
      return ` (${history.approved_level})`;
    } else {
      return "";
    }
  };

  return (
    <ExpandableSection
      sectionId={"request-history"}
      title="Request History"
      expandedByDefault={true}
    >
      <div className="molds-table-container">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Status</th>
              <th scope="col">Timestamp</th>
              <th scope="col">User</th>
              <th scope="col">Comment</th>
            </tr>
          </thead>
          <tbody>
            <tr></tr>
            {state.requestHistory.map((history: ITccRequestHistory, index) => (
              <tr key={index}>
                <td>{`${history.status}${getStatusExtraInformation(history)}`}</td>
                <td>{DateTimeFormat.formatTimestamp(history.timestamp)}</td>
                <td>{history.user}</td>
                <td>{history.comment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ExpandableSection>
  );
};

export default TccRequestHistorySection;
